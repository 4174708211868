import Vue from "vue";
import { Component } from 'vue-property-decorator'
import './landing.scss'
import { UserService } from 'service'
import { Routes } from 'route/routes'
import ApiService from './landing.api'
import NotifyGroup from 'component/notify'
import Spin from 'component/spin'
import Input from 'component/input/input'
import Button from 'component/button/button'
import Text from 'component/text/text'
import Gap from 'component/grid/gap'
import CacheService from 'service/cacheService'

@Component
export default class PageLanding extends Vue {
  private username: string = ''
  private password: string = ''

  private get elPassword(): HTMLInputElement {
    return this.$refs.password as HTMLInputElement
  }

  onUsernamePressed(e: KeyboardEvent) {
    if (e.which !== 13) return

    this.password !== ''
      ? this.login()
      : this.elPassword.focus()
  }

  onPasswordPressed(e: KeyboardEvent) {
    if (e.which === 13) this.login()
  }

  async login() {
    try {
      this.$spin.show('正在登录中...')
      CacheService.open('weaap')
      CacheService.set('weaapUser', { username: this.username, password: this.password })
      const user = await UserService.login(this.username, this.password)

      window.location.assign(`${location.origin}/?user=${user._id}`)
    } catch (e) {
      this.$notify.error(e.message)
    } finally {
      this.$spin.hide()
    }
  }

  async mounted() {
    this.$nextTick(async () => {
      CacheService.open('weaap')
      let { username, password } = await CacheService.get('weaapUser')
      this.username = username
      this.password = password
    })
  }

  render(h) {
    return (
      <div class="mobilePageLanding">

        <div class="land-content">
          {/* <div class="logo"></div> */}

          <div class="form">
            <div class="account form-item">
              <div class="form-label">
                用户名
              </div>
              <div class="form-value">
                <input ref="username" type="text" placeholder="请输入账号"
                  value={this.username} onInput={e => this.username = e.target.value} autocomplete="off" />
              </div>
            </div>
            <div class="account form-item">
              <div class="form-label">
                密码
              </div>
              <div class="form-value">

                <input ref="password" type="password" placeholder="请输入密码"
                  value={this.password} onInput={e => this.password = e.target.value} autocomplete="off" />
              </div>
            </div>

            <div class="btn-login" onClick={() => this.login()}>
              登陆
            </div>
          </div>
        </div>



        <NotifyGroup></NotifyGroup>
        <Spin></Spin>
      </div>
    )
  }
}