import '@babel/polyfill'
import 'service/logService'

import Vue from 'vue'
import PageLanding from 'page/landing'
import 'style/global.scss'
import 'style/framework.scss'
import MobilePageLanding from 'custom/weapp/landing'
import { isMobile } from 'util/common'

new Vue({
  el: '#app',
  render: h => h(!isMobile() ? PageLanding : MobilePageLanding)
})
